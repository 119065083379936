
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import {
		TEmailNotificationOptionKey,
		UNSUBSCRIBE_EMAIL_OPTIONS,
		UserState,
		EmailNotifications as TEmailNotifications,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import CheckBox from '@pixcap/ui-library/components/CheckBox.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';

	@Component({
		name: 'EmailNotifications',
		components: {
			CheckBox,
			Button,
			BodyText,
			CaptionText,
		},
	})
	export default class EmailNotifications extends Vue {
		@Prop({ required: true })
		private readonly emailNotifications: UserState['emailNotifications'];

		private readonly LIST_ALERT_AND_NEWSLETTER_OPTIONS = [
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.DOWNLOADS,
				{
					// @ts-ignore
					title: this.$t ? this.$t('page.user_settings.email_notification.downloads') : 'Downloads',
					// @ts-ignore
					description: this.$t
						? // @ts-ignore
						  this.$t('page.user_settings.email_notification.downloads_text')
						: 'Receive notifications when the high-quality & AI images are available for download',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.EVENTS,
				{
					// @ts-ignore
					title: this.$t ? this.$t('page.user_settings.email_notification.events') : 'Events',
					// @ts-ignore
					description: this.$t
						? // @ts-ignore
						  this.$t('page.user_settings.email_notification.events_text')
						: 'Get notified when your preferred event packs are published',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.NEWSLETTERS,
				{
					// @ts-ignore
					title: this.$t ? this.$t('page.user_settings.email_notification.newsletters') : 'Newsletters',
					// @ts-ignore
					description: this.$t
						? // @ts-ignore
						  this.$t('page.user_settings.email_notification.newsletters_text')
						: 'Get marketing newsletters for news & design inspirationss',
				},
			],
		];
		private readonly LIST_ACCOUNT_ACTIVITY_OPTIONS = [
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.COMMENTS,
				{
					// @ts-ignore
					description: this.$t ? this.$t('page.user_settings.email_notification.comments') : 'Someone comments on my project',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.MENTION_REPLY,
				{
					// @ts-ignore
					description: this.$t ? this.$t('page.user_settings.email_notification.mentions') : 'Someone mentions me',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.PROJECT_INVITATION,
				{
					// @ts-ignore
					description: this.$t
						? // @ts-ignore
						  this.$t('page.user_settings.email_notification.collab_invites')
						: 'I receive invitations to collaborate on their projects',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.REMINDERS,
				{
					// @ts-ignore
					description: this.$t ? this.$t('page.user_settings.email_notification.pending_actions') : 'Pending actions',
				},
			],
			[
				UNSUBSCRIBE_EMAIL_OPTIONS.THREAD,
				{
					// @ts-ignore
					description: this.$t ? this.$t('page.user_settings.email_notification.conversation') : 'Conversation in projects',
				},
			],
		];
		private readonly ALL_OPTIONS_IDS = Object.keys(UNSUBSCRIBE_EMAIL_OPTIONS).reduce((finalItem, key) => {
			key !== 'ALL' && finalItem.push(UNSUBSCRIBE_EMAIL_OPTIONS[key]);
			return finalItem;
		}, []);
		private selectedIds = [];
		private isSubmitting = false;

		mounted() {
			UserActions.fetchEmailNotifications(this.$store);
		}

		isChecked(id: TEmailNotificationOptionKey) {
			return this.selectedIds.includes(id);
		}

		onChangeCheckbox(id: TEmailNotificationOptionKey) {
			const isChecked = this.isChecked(id);
			if (isChecked) {
				this.selectedIds = this.selectedIds.filter((i) => i !== id);
				return;
			}
			this.selectedIds.push(id);
		}

		parseEmailNotificationsToIdArray(obj: TEmailNotifications) {
			if (obj.ALL === false) return [];
			const clonedIds = new Set([...(obj.ALL ? this.ALL_OPTIONS_IDS : [])]);
			const setIds = Object.entries(obj).reduce((finalItem, each) => {
				each[1] ? finalItem.add(each[0]) : finalItem.delete(each[0]);
				return finalItem;
			}, clonedIds);
			return [...setIds];
		}

		checkIsEditted(arr1: any[], arr2: any[]) {
			if (arr1.length !== arr2.length) return false;
			const sortedArr1 = arr1.slice().sort();
			const sortedArr2 = arr2.slice().sort();
			let i = 0;
			for (i = 0; i < sortedArr1.length; i++) {
				if (sortedArr1[i] !== sortedArr2[i]) return false;
			}
			return true;
		}

		get computedPayload() {
			return this.ALL_OPTIONS_IDS.reduce((finalItem, each) => {
				finalItem[each] = this.selectedIds.includes(each);
				return finalItem;
			}, {});
		}

		get computedIsEdited() {
			if (!this.selectedIds.length) return false;
			const originValues = this.parseEmailNotificationsToIdArray(this.emailNotifications);
			const currentValues = this.selectedIds;
			const areEqual = this.checkIsEditted(currentValues, originValues);
			return !areEqual;
		}

		async handleSubmitForm() {
			this.isSubmitting = true;
			await UserActions.updateEmailNotification(this.$store, this.computedPayload);
			this.isSubmitting = false;
		}

		@Watch('emailNotifications', { immediate: true, deep: true })
		watchEmailNotifications(val: UserState['emailNotifications']) {
			this.selectedIds = this.parseEmailNotificationsToIdArray(val);
		}
	}
