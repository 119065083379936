
	import { Component, Vue } from 'vue-property-decorator';
	import { NAMESPACE as AUTH_NAMESPACE, ActionTypes as AuthActionTypes } from '@pixcap/ui-core/models/store/auth.interface';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import { validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { $notify } from '@pixcap/ui-core/helpers/notification';

	import Button from '@pixcap/ui-library/components/Button.vue';
	import InputWrapper from '@pixcap/ui-library/components/InputWrapper.vue';

	@Component({
		name: 'ChangePasswordForm',
		components: {
			Button,
			InputWrapper,
		},
	})
	export default class ChangePasswordForm extends Vue {
		password = '';
		newPassword = '';
		passwordConfirmation = '';

		errorMsg: { [key: string]: string } = {};
		isUpdatingPassword = false;

		validatePasswordUtil = validatePassword;

		updateError(key: string, isValid: boolean, message?: string) {
			if (isValid) {
				this.$delete(this.errorMsg, key);
			} else {
				this.$set(this.errorMsg, key, message);
			}
		}

		validatePassword() {
			if (!this.password) {
				// @ts-ignore
				this.updateError('password', false, this.$t ? this.$t('page.user_settings.change_password.error_1') : 'Password cannot be empty');
			} else {
				this.updateError('password', true);
			}
		}

		validateNewPassword() {
			// validate new password validity: empty and format
			if (!this.newPassword) {
				// @ts-ignore
				this.updateError('newPassword', false, this.$t ? this.$t('page.user_settings.change_password.error_2') : 'New password cannot be empty');
			} else if (!this.validatePasswordUtil(this.newPassword)) {
				// @ts-ignore
				this.updateError('newPassword', false, this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE);
			} else {
				this.updateError('newPassword', true);
			}
		}

		validatePasswordConfirmation() {
			// validate password confirmation: equal new password
			if (!this.passwordConfirmation) {
				this.updateError(
					'passwordConfirmation',
					false,
					// @ts-ignore
					this.$t ? this.$t('page.user_settings.change_password.error_3') : 'Password confirmation cannot be empty'
				);
			} else if (this.passwordConfirmation !== this.newPassword) {
				this.updateError(
					'passwordConfirmation',
					false,
					// @ts-ignore
					this.$t ? this.$t('page.user_settings.change_password.error_4') : 'The passwords do not match'
				);
			} else {
				this.updateError('passwordConfirmation', true);
			}
		}

		resetTabIndex(e) {
			e.preventDefault();
			let form = this.$refs.changePasswordForm as { getElementsByTagName: Function };
			form.getElementsByTagName('input')[0].focus();
		}

		resetForm() {
			this.password = '';
			this.newPassword = '';
			this.passwordConfirmation = '';
		}

		validateForm() {
			this.validatePassword();
			this.validateNewPassword();
			this.validatePasswordConfirmation();
		}

		updatePassword() {
			this.validateForm();
			let passSubmit = this.isUpdatingPassword || Object.keys(this.errorMsg).length;
			if (!passSubmit) {
				this.isUpdatingPassword = true;
				this.$store
					.dispatch(
						`${AUTH_NAMESPACE}/${AuthActionTypes.CHANGE_PASSWORD}`,
						{
							oldPassword: this.password,
							newPassword: this.newPassword,
						},
						{ root: true }
					)
					.then(() => {
						this.resetForm();
						this.errorMsg = {};
						$notify({
							type: NotificationType.SUCCESS,
							title: 'You have changed password successfully',
							duration: 3000,
						});
					})
					.catch(() => {
						// incorrect password
						// @ts-ignore
						this.updateError('password', false, this.$t ? this.$t('page.user_settings.change_password.error_5') : 'Password incorrect');
					})
					.finally(() => {
						this.isUpdatingPassword = false;
					});
			}
		}
	}
