
	import { Vue, Component, Provide, Prop } from 'vue-property-decorator';
	import EmailLogin from '@pixcap/ui-core/components/account/authentication/EmailLogin.vue';
	import LoginOptions from '@pixcap/ui-core/components/account/authentication/LoginOptions.vue';
	import LayoutIllustrations from '@pixcap/ui-core/components/account/authentication/LayoutIllustrations.vue';

	@Component({
		name: 'AuthenticationLayout',
		components: { EmailLogin, LoginOptions, LayoutIllustrations },
	})
	export default class AuthenticationLayout extends Vue {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		@Prop() title: string;
		@Prop() description: string;

		isLoginWithEmailSelected = false;
		@Provide()
		onSignInSuccessfully() {
			this.$emit('onSignInSuccessfully');
		}
	}
