import { render, staticRenderFns } from "./ResetPasswordForm.vue?vue&type=template&id=5d4f4bf3&scoped=true&"
import script from "./ResetPasswordForm.vue?vue&type=script&lang=ts&"
export * from "./ResetPasswordForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4f4bf3",
  null
  
)

export default component.exports