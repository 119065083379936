
	import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';
	import { validateEmail } from '@pixcap/ui-core/utils/ValidationUtils';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';

	@Component({
		name: 'EmailForm',
		components: {
			InputWrapperAuth,
			Button,
			HeadingText,
			BodyText,
		},
	})
	export default class EmailForm extends Mixins<FormMixin<{ email?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		email = '';

		get disableSubmit() {
			return !this.email || this.errorMsg.email || this.isSubmitting;
		}

		@Watch('email')
		handleValidateEmail(email: string) {
			if (email) {
				this.updateError(
					'email',
					validateEmail(email),
					// @ts-ignore
					this.$t ? this.$t('navbar.login_form.email_login.error_email') : 'Please enter a valid email.'
				);
			}
		}

		async onSubmit() {
			if (this.disableSubmit) return;
			this.setLoginEmail(this.email);
			const { isUnique, isSocialAuth } = await AuthActions.checkEmailUniqueness(this.$store, this.email);
			if (isUnique) this.$emit('onStepChange', LOGIN_STEPS.CREATE_ACCOUNT_FORM);
			else {
				if (!isSocialAuth) this.$emit('onStepChange', LOGIN_STEPS.LOGIN_FORM);
				else {
					this.$notify({
						type: NotificationType.ERROR,
						title: 'Please use Google to connect.',
						duration: 3000,
					});
					this.$emit('onBack');
				}
			}
		}

		created() {
			this.email = this.loginEmail;
		}
	}
