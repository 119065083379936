
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import AuthenticationLayout from '@pixcap/ui-core/components/account/authentication/AuthenticationLayout.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';

	@Component({
		name: 'AuthenticationModal',
		components: {
			AuthenticationLayout,
			PixcapLogo,
			BaseModal,
		},
	})
	export default class AuthenticationModal extends Vue {
		onSignInSuccessfully() {
			if (window.parent.origin == window.origin) {
				window.parent.postMessage('login_successfully', '*');
			}
			const onAuthenticated = this.$pixcap.$services.authService.getOnAuthenticated();
			UserActions.fetchPersonalData(this.$store).then(({ subscriptionInfo }) => {
				if (onAuthenticated) {
					onAuthenticated(subscriptionInfo);
				}
			});
			try {
				const redirectPath = new URLSearchParams(window.location.search).get('redirect');
				if (redirectPath) window.location.href = redirectPath;
			} catch {}

			this.$emit('onSignInSuccessfully');
			this.close();
		}

		close() {
			this.$pixcap.$services.authService.setOnAuthenticated(undefined);
			UserMutations.showAuthenticationModal(this.$store, false);
			if (window.parent.origin == window.origin) {
				window.parent.postMessage('close_authentication_prompt', '*');
			}
			this.$emit('onClose');
		}
	}
