
	import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';

	@Component({
		components: { InputWrapperAuth, Button, HeadingText, BodyText },
	})
	export default class LoginForm extends Mixins<FormMixin<{ password?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		password = '';

		@Inject() onSignInSuccessfully!: () => void;

		get disableSubmit() {
			return !this.password || this.errorMsg.password || this.isSubmitting;
		}

		@Watch('password')
		handleValidatePassword(password: string) {
			this.updateError(
				'password',
				validatePassword(password),
				// @ts-ignore
				this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE
			);
		}

		handleForgetPassword() {
			AuthActions.forgetPassword(this.$store, { email: this.loginEmail, passUrl: false });
			this.$emit('onStepChange', LOGIN_STEPS.RESET_CODE_FORM);
		}
		async onSubmit() {
			if (this.disableSubmit) return;
			try {
				const response = await AuthActions.signIn(this.$store, { email: this.loginEmail, password: this.password });
				await AuthActions.store(this.$store, response as any);
				if (this.onSignInSuccessfully) this.onSignInSuccessfully();
			} catch (error) {
				const errorCode = error?.data?.errorCode;
				switch (errorCode) {
					case 'E02_02_004':
					case 'E02_02_001': {
						this.updateError(
							'password',
							false,
							// @ts-ignore
							this.$t ? this.$t('navbar.login_form.email_login.error_E02_02_001') : 'Email or password is incorrect.'
						);
						break;
					}
					default: {
						$notify({
							type: NotificationType.ERROR,
							// @ts-ignore
							title: this.$t ? this.$t('navbar.login_form.email_login.error_default') : 'Failed to login please try again',
						});
					}
				}
			}
		}
	}
