
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	@Component({
		name: 'AccountNavLink',
		components: {
			BodyText,
		},
	})
	export default class AccountNavLink extends Vue {
		@Prop() readonly text: string;
		@Prop() readonly active: boolean;

		handleMouseDown() {
			this.$emit('click');
		}
	}
