
	import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';

	@Component({
		name: 'ResetPasswordForm',
		components: { InputWrapperAuth, Button, HeadingText, BodyText },
	})
	export default class ResetPasswordForm extends Mixins<FormMixin<{ newPassword: string; rePassword?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		newPassword = '';
		rePassword = '';

		@Inject() onResetPasswordSuccessfully!: () => void;

		get disableReset() {
			return !(this.newPassword && this.rePassword === this.newPassword) || this.isSubmitting || Object.keys(this.errorMsg).length;
		}

		@Watch('newPassword')
		onNewPasswordChange(newPassword) {
			if (newPassword) this.handleValidatePassword(newPassword);
		}

		@Watch('rePassword')
		onReasswordChange(rePassword) {
			if (rePassword) this.handleValidateRePassword(rePassword);
		}

		handleValidatePassword(password: string) {
			this.updateError(
				'newPassword',
				validatePassword(password),
				// @ts-ignore
				this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE
			);
			this.handleValidateRePassword(this.rePassword);
		}

		handleValidateRePassword(rePassword: string) {
			this.updateError(
				'rePassword',
				rePassword === this.newPassword,
				// @ts-ignore
				this.$t ? this.$t('common_elements.error_password_not_matched') : 'The password confirmation does not match.'
			);
		}

		async onSubmit() {
			if (this.disableReset) return;
			try {
				await AuthActions.resetPassword(this.$store, {
					password: this.newPassword,
					email: this.loginEmail,
					confirmationCode: this.resetPasswordCode,
				});
				if (this.onResetPasswordSuccessfully) this.onResetPasswordSuccessfully();
			} catch (error) {
				AuthActions.forgetPassword(this.$store, { email: this.loginEmail, passUrl: false });
				this.$emit('onStepChange', LOGIN_STEPS.RESET_CODE_FORM);
			}
		}
	}
