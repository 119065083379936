
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { PIXCAP_PRIVACY_LINK, PIXCAP_TERMS_LINK } from '@pixcap/ui-core/constants/app.constants';
	import SocialAuthLogin from '@pixcap/ui-core/components/account/authentication/SocialAuthLogin.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import IconGoogle from '@pixcap/ui-library/components/Icons/IconGoogle.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	@Component({
		components: { Button, IconGoogle, HeadingText, CaptionText, BodyText, PixcapLogo, SocialAuthLogin },
	})
	export default class LoginOptions extends Vue {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		@Prop() title: string;
		@Prop() description: string;

		get displayTitle() {
			if (this.title) return this.title;
			return this.$t ? this.$t('navbar.login_form.title') : 'Unlock 15,000+ customizable 3D assets';
		}

		get displayDescription() {
			if (this.description) return this.description;
			return this.$t ? this.$t('navbar.login_form.description') : 'High-quality 3D animated icons, 3D characters and mockups.';
		}

		PIXCAP_PRIVACY_LINK = PIXCAP_PRIVACY_LINK;
		PIXCAP_TERMS_LINK = PIXCAP_TERMS_LINK;
		loginWithEmail() {
			this.$emit('onLoginWithEmail');
		}
	}
