
	import { Vue, Component, Provide, Prop } from 'vue-property-decorator';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconArrowLeft from '@pixcap/ui-library/components/Icons/IconArrowLeft.vue';
	import EmailForm from '@pixcap/ui-core/components/account/authentication/email-login/EmailForm.vue';
	import LoginForm from '@pixcap/ui-core/components/account/authentication/email-login/LoginForm.vue';
	import ResetCodeForm from '@pixcap/ui-core/components/account/authentication/email-login/ResetCodeForm.vue';
	import ResetPasswordForm from '@pixcap/ui-core/components/account/authentication/email-login/ResetPasswordForm.vue';
	import CreateAccountForm from '@pixcap/ui-core/components/account/authentication/email-login/CreateAccountForm.vue';
	import EmailVerificationForm from '@pixcap/ui-core/components/account/authentication/email-login/EmailVerificationForm.vue';

	const STEP_DEFINITION = {
		[LOGIN_STEPS.EMAIL_FORM]: EmailForm,
		[LOGIN_STEPS.LOGIN_FORM]: LoginForm,
		[LOGIN_STEPS.EMAIL_VERIFICATION_FORM]: EmailVerificationForm,
		[LOGIN_STEPS.RESET_PASSWORD_FORM]: ResetPasswordForm,
		[LOGIN_STEPS.CREATE_ACCOUNT_FORM]: CreateAccountForm,
		[LOGIN_STEPS.RESET_CODE_FORM]: ResetCodeForm,
	};

	@Component({
		name: 'EmailLogin',
		components: {
			IconArrowLeft,
			BodyText,
		},
	})
	export default class EmailLogin extends Vue {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		stepsHistory: LOGIN_STEPS[] = [];
		currentStep = LOGIN_STEPS.EMAIL_FORM;

		get currentStepComponent() {
			return STEP_DEFINITION[this.currentStep];
		}

		@Provide()
		onResetPasswordSuccessfully() {
			this.stepsHistory = [LOGIN_STEPS.EMAIL_FORM];
			this.currentStep = LOGIN_STEPS.LOGIN_FORM;
		}

		handleBack() {
			if (this.stepsHistory.length > 0) this.currentStep = this.stepsHistory.pop();
			else this.$emit('onLoginWithEmail');
		}

		handleChangeStep(step: LOGIN_STEPS) {
			this.stepsHistory.push(this.currentStep);
			this.currentStep = step;
		}
	}
