
	import { Component, Vue } from 'vue-property-decorator';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, ReferralType, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import { FreebiesTypes } from '@pixcap/ui-core/models/store/app.interface';
	import { appUtilities } from '@/modules/appUtilities';
	import { SHOULD_SHOW_REFERRAL_MODAL } from '@pixcap/ui-core/constants/auth.constants';

	@Component({
		components: { IconCheck, BodyText, HeadingText, BaseModal, Button, CaptionText },
		computed: {
			...mapState(USER_NAMESPACE, {
				referralsInfo: (state: UserState) => state.referralsInfo,
				personalData: (state: UserState) => state.personalData,
			}),
		},
	})
	export default class ReferralModal extends Vue {
		personalData: UserState['personalData'];
		referralsInfo: UserState['referralsInfo'];

		get defaultImage() {
			return `${_PIXCAP_ENV.ROOT_URL}/cdn/app/referral/succeed-modal-thumbnail.webp`;
		}

		get watermarkInfos() {
			return this.referralsInfo.rewards.find((reward) => reward.name === FreebiesTypes.WATERMARK_FREE);
		}

		get isReferee() {
			return this.watermarkInfos.type !== ReferralType.REFEREE;
		}

		get title() {
			if (this.isReferee) return `Congrats! Enjoy watermark-free downloads forever thanks to ${this.watermarkInfos.source} 🎉`;
			else return 'Congrats! Enjoy watermark-free downloads forever 🎉';
		}

		closeModal() {
			this.$pixcap.$mixpanel.track('Referral: Redeemed referral reward');
			appUtilities.$services.localStorageManager.write(SHOULD_SHOW_REFERRAL_MODAL, 0);
			UserMutations.setShowReferralModal(this.$store, null);
		}
	}
