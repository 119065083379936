
	import { Component, Inject, Mixins } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconGoogle from '@pixcap/ui-library/components/Icons/IconGoogle.vue';
	import SocialAuthMixin from '@pixcap/ui-core/components/account/common/SocialAuthMixin';

	@Component({
		name: 'SocialAuthLogin',
		components: {
			IconGoogle,
			BodyText,
			Button,
		},
	})
	export default class SocialAuthLogin extends Mixins(SocialAuthMixin) {
		@Inject() onSignInSuccessfully!: () => void;

		onSuccessfully() {
			if (this.onSignInSuccessfully) this.onSignInSuccessfully();
		}
	}
