
	import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';

	@Component({
		name: 'CreateAccountForm',
		components: { InputWrapperAuth, Button, HeadingText, BodyText },
	})
	export default class CreateAccountForm extends Mixins<FormMixin<{ password?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		password = '';

		get disableSubmit() {
			return !this.password || this.errorMsg.password || this.isSubmitting;
		}

		@Watch('password')
		handleValidatePassword(password: string) {
			this.updateError(
				'password',
				validatePassword(password),
				// @ts-ignore
				this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE
			);
		}

		async onSubmit() {
			if (this.disableSubmit) return;
			await AuthActions.signUp(this.$store, { password: this.password, email: this.loginEmail });
			this.$emit('onStepChange', LOGIN_STEPS.EMAIL_VERIFICATION_FORM);
		}
	}
