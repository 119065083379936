
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'ResendCodeButton',
		components: { BodyText },
	})
	export default class ResendCodeButton extends Vue {
		@Prop() resendDelay: number;

		intervalSeconds = 0;
		resendCodeInterval = null;

		get disableResendCode() {
			return this.intervalSeconds > 0;
		}

		handleResendCode() {
			if (this.disableResendCode) return;
			this.$emit('onResendCode');
			this.initResendCodeInterval();
		}

		initResendCodeInterval() {
			this.intervalSeconds = this.resendDelay;
			this.resendCodeInterval = setInterval(() => {
				if (this.intervalSeconds <= 0) clearInterval(this.resendCodeInterval);
				this.intervalSeconds -= 1;
			}, 1000);
		}

		created() {
			this.initResendCodeInterval();
		}

		beforeDestroy() {
			clearInterval(this.resendCodeInterval);
		}
	}
