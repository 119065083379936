
	import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import ResendCodeButton from '@pixcap/ui-core/components/account/authentication/email-login/ResendCodeButton.vue';

	@Component({
		name: 'EmailVerificationForm',
		components: { ResendCodeButton, InputWrapperAuth, Button, HeadingText, BodyText },
	})
	export default class EmailVerificationForm extends Mixins<FormMixin<{ verificationCode?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		verificationCode = '';

		get disableSubmit() {
			return this.isSubmitting || !this.verificationCode;
		}

		@Watch('verificationCode')
		onVerificationCodeChange() {
			if (this.errorMsg.verificationCode) this.updateError('verificationCode', true);
		}

		@Inject() onSignInSuccessfully!: () => void;

		handleResendCode() {
			AuthActions.resendEmailVerification(this.$store, this.loginEmail);
		}
		async onSubmit() {
			if (this.disableSubmit) return;
			const response = await AuthActions.verifyEmail(this.$store, { email: this.loginEmail, verificationKey: this.verificationCode });
			if (response) {
				await AuthActions.store(this.$store, response as any);
				if (this.onSignInSuccessfully) this.onSignInSuccessfully();
			} else {
				this.updateError(
					'verificationCode',
					false,
					//@ts-ignore
					this.$t ? this.$t('navbar.login_form.email_login.invalid_code') : 'Entered code is not valid, try again'
				);
			}
		}
	}
