import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { NAMESPACE as AUTH_NAMESPACE, IAuthState } from '@pixcap/ui-core/models/store/auth.interface';
import { mutationsWrapper as AuthMutations } from '@pixcap/ui-core/store/auth';

@Component({
	computed: {
		...mapState(AUTH_NAMESPACE, {
			isSubmitting: (state: IAuthState) => state.isSubmitting,
			loginEmail: (state: IAuthState) => state.loginEmail,
			resetPasswordCode: (state: IAuthState) => state.resetPasswordCode,
		}),
	},
})
export default class FormMixin<FormState> extends Vue {
	isSubmitting: IAuthState['isSubmitting'];
	loginEmail: IAuthState['loginEmail'];
	resetPasswordCode: IAuthState['resetPasswordCode'];
	//@ts-ignore
	errorMsg: FormState = {};

	$refs: {
		refForm: HTMLElement;
	};

	updateError(key: string, isValid: boolean, message?: string) {
		const errors = this.errorMsg;
		if (isValid) {
			const currentErrors = { ...errors };
			delete currentErrors[key];
			this.errorMsg = currentErrors;
		} else {
			this.errorMsg = {
				...errors,
				[key]: message,
			};
		}
	}

	resetTabIndex(e) {
		e.preventDefault();
		const form = this.$refs.refForm;
		form.getElementsByTagName('input')[0].focus();
	}

	setLoginEmail(email: string) {
		AuthMutations.updateLoginEmail(this.$store, email);
	}
}
