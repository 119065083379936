
	import { Component, Mixins, Prop } from 'vue-property-decorator';
	import { actionsWrapper as AuthActions, mutationsWrapper as AuthMutations } from '@pixcap/ui-core/store/auth';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import ResendCodeButton from '@pixcap/ui-core/components/account/authentication/email-login/ResendCodeButton.vue';
	import { LOGIN_STEPS } from '@pixcap/ui-core/models/store/auth.interface';

	@Component({
		name: 'ResetCodeForm',
		components: { ResendCodeButton, InputWrapperAuth, Button, HeadingText, BodyText },
	})
	export default class ResetCodeForm extends Mixins<FormMixin<{ resetCode?: string }>>(FormMixin) {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		resetCode = '';

		get disableSubmit() {
			return this.isSubmitting || !this.resetCode;
		}

		handleResendCode() {
			AuthActions.forgetPassword(this.$store, { email: this.loginEmail, passUrl: false });
		}
		onSubmit() {
			if (this.disableSubmit) return;
			AuthMutations.updateResetPasswordCode(this.$store, this.resetCode);
			this.$emit('onStepChange', LOGIN_STEPS.RESET_PASSWORD_FORM);
		}
	}
