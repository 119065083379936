
	import { Component, Vue, Prop } from 'vue-property-decorator';

	/// icons list
	import IconEye from '@pixcap/ui-library/components/Icons/IconEye.vue';
	import IconFailed from '@pixcap/ui-library/components/Icons/IconFailed.vue';
	import IconEyeBlock from '@pixcap/ui-library/components/Icons/IconEyeBlock.vue';

	@Component({
		name: 'InputWrapperAuth',
		components: {
			IconEyeBlock,
			IconEye,
			IconFailed,
		},
	})
	export default class InputWrapperAuth extends Vue {
		@Prop() readonly inputLabel: string;
		@Prop() readonly errorMsg: string;
		@Prop() readonly value: string;
		@Prop() readonly disabled: boolean;
		@Prop() readonly type: 'text' | 'email' | 'password' | 'number' | 'textarea';
		@Prop() readonly readonly: boolean;
		@Prop() readonly placeholder: string;
		@Prop({ default: 'medium' }) readonly size: 'small' | 'medium';
		@Prop() readonly errorIndicator: boolean;
		@Prop({ default: '3' }) rows: string;
		@Prop() maxlength: string;

		isPasswordVisible = false;
		iconSize = 15;

		get showIndicatorError() {
			return this.errorMsg && this.errorIndicator;
		}

		get isPasswordInput() {
			return this.type === 'password';
		}

		get inputType() {
			if (this.isPasswordInput && this.isPasswordVisible) return 'text';
			return this.type;
		}

		get isNotTextArea() {
			return this.type !== 'textarea';
		}
		handleInput(e) {
			this.$emit('input', e.target.value);
		}
	}
