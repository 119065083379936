
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	@Component({
		name: 'LayoutIllustrations',
	})
	export default class LayoutIllustrations extends Vue {
		@Prop({ default: 'medium' }) size: 'medium' | 'large';
		itemsPerRow = 2;
		activeIllustration = 0;
		illustrationsList = [
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-01.png`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-02.png`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-11.png`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-12.png`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-21.png`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/authentication/login-illustration-22.png`,
		];
		_sliderTimeout = null;
		_handleScreenResize = null;

		get items() {
			const items = this.illustrationsList;
			const itemsPerRow = this.itemsPerRow;
			const result = [];
			for (let i = 0; i < items.length; i += itemsPerRow) {
				result.push(items.slice(i, i + itemsPerRow));
			}
			return result;
		}

		handleSwitchSlider() {
			this.activeIllustration = (this.activeIllustration + 1) % this.items.length;
			this._initSliderTimeout();
		}

		_initSliderTimeout() {
			clearTimeout(this._sliderTimeout);
			this._sliderTimeout = setTimeout(this.handleSwitchSlider, 4000);
		}

		handleSelectIllustration(index: number) {
			this.activeIllustration = index;
			this._initSliderTimeout();
		}

		handleScreenResize() {
			if (window.innerWidth <= 1200) {
				if (this.itemsPerRow == 2) this.itemsPerRow = 1;
			} else {
				if (this.itemsPerRow == 1) this.itemsPerRow = 2;
			}
		}

		mounted() {
			this._handleScreenResize = this.handleScreenResize.bind(this);
			this.handleScreenResize();
			this._initSliderTimeout();
			window.addEventListener('resize', this._handleScreenResize);
		}

		beforeDestroy() {
			clearTimeout(this._sliderTimeout);
			window.removeEventListener('resize', this._handleScreenResize);
		}
	}
